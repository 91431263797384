.home-screen {
  min-height: 100vh;
  background-color: black;
}

.opacityTransition {
  transition: opacity 1s ease-out;
}

canvas {
  display: block;
}

.loader {
  transition: visibility 1s ease-out;
}

.loader-circle {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  transform: translate(-50%,-50%);
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px rgba(212, 175, 55, 0.1);
}
.loader-line-mask {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 75px;
  height: 150px;
  margin-left: -75px;
  margin-top: -75px;
  transform-origin: 100% 50%;
  overflow: hidden;
  -webkit-mask-image: -webkit-linear-gradient(top,#000000,rgba(0,0,0,0));
  animation: rotate 1.2s infinite linear;
}
.loader-line-mask .loader-line {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px #d4af37;
}

@keyframes rotate {
  0% { transform: rotate(0deg);} 100% { transform: rotate(360deg);}
}

.landing-content {
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  position: relative;
  opacity: 0;
  transition: opacity 1s ease-out;
}

.home-logo {
  height: 10vh;
}